const OnElementScrollPort = {
  add: function (app) {
    var sendElementScroll = function (element) {
      var scrollInfo = {
        scrollTop: element.scrollTop,
        scrollHeight: element.scrollHeight,
        clientHeight: element.clientHeight,
        atTop: element.scrollTop <= 0,
        atBottom:
          element.scrollHeight - element.scrollTop <= element.clientHeight,
      };

      app.ports.onElementScroll.send(scrollInfo);
    };

    app.ports.setupElementScroll.subscribe((elementId) => {
      // wait for element creation, if it doesn't exist yet
      var waitTimer = null;
      var waitInterval = 100;

      var waitForElementCreation = function () {
        var element = document.getElementById(elementId);

        if (element) {
          clearTimeout(waitTimer);
          listenToElementScroll(element);
        } else {
          waitTimer = setTimeout(function () {
            clearTimeout(waitTimer);
            waitForElementCreation();
          }, waitInterval);
        }
      };

      var scrollTimer = null;
      var lastScrollFireTime = 0;
      var minScrollTime = 100;

      var listenToElementScroll = function (element) {
        if (element) {
          // send scroll event for initial position
          sendElementScroll(element);

          element.addEventListener("scroll", () => {
            if (!scrollTimer) {
              var now = new Date().getTime();
              if (now - lastScrollFireTime > minScrollTime) {
                sendElementScroll(element);
                lastScrollFireTime = now;
              }
              scrollTimer = setTimeout(function () {
                scrollTimer = null;
                lastScrollFireTime = new Date().getTime();
                sendElementScroll(element);
              }, minScrollTime);
            }
          });
        }
      };

      waitForElementCreation();
    });
  },
};

module.exports = OnElementScrollPort;
