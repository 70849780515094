const GooglePlacesPort = {
  add: function (app, googleMapsKey) {
    if (!googleMapsKey) {
      throw new Error("Google Maps Key is not configured.");
    }

    window.setupGoogleMaps(googleMapsKey);

    window.initMap = function () {
      app.ports.googlePlacesPredictAddress.subscribe(function ({
        address,
        id,
      }) {
        if (!address) {
          return;
        }
        var service = new google.maps.places.AutocompleteService();
        var options = { input: address, types: ["address"] };
        service.getPlacePredictions(options, function (predictions) {
          app.ports.googlePlacesAddressPredictions.send({ predictions, id });
        });
      });

      app.ports.googlePlacesPredictionDetails.subscribe(function ({
        placeId,
        id,
      }) {
        var request = { placeId: placeId };
        var service = new google.maps.places.PlacesService(
          document.createElement("div"),
        );
        service.getDetails(request, function (place) {
          // utf offset is depracted, we don't want to stringify it.
          delete place["utc_offset"];
          var address = JSON.stringify(place);
          app.ports.googlePlacesAddressDetails.send({ address, id });
        });
      });
    };
  },
};

module.exports = GooglePlacesPort;
