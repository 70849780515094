"use strict";

const FaviconPort = {
  add: function (app) {
    if (app.ports.setFaviconPort) {
      app.ports.setFaviconPort.subscribe((favicons) => {
        let icoLink = document.getElementById("faviconIco");
        let pngLink = document.getElementById("faviconPng");
        let appleLink = document.getElementById("faviconApple");

        icoLink.setAttribute("href", favicons.icoUrl);
        pngLink.setAttribute("href", favicons.pngUrl);
        appleLink.setAttribute("href", favicons.appleUrl);
      });
    }
  },
};

module.exports = FaviconPort;
