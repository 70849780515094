import { storageFactory } from "storage-factory";

const storage = storageFactory(() => localStorage);

const LocalStoragePort = {
  getItem: storage.getItem,
  add: (app) => {
    app.ports.setItemPort.subscribe((object) => {
      storage.setItem(object.key, object.value);

      // Uncomment if you want a SetItem callback
      // app.ports.setItemPortCompleted.send({ value: object.value, itemType: object.itemType, action: object.action });
    });

    app.ports.getItemPort.subscribe(function (object) {
      const value = storage.getItem(object.key);

      app.ports.gotItemPort.send({
        value: value,
        itemType: object.itemType,
        action: object.action,
      });
    });

    app.ports.removeItemPort.subscribe(function (object) {
      storage.removeItem(object.key);

      app.ports.removedItemPort.send({
        value: "",
        itemType: object.itemType,
        action: object.action,
      });
    });

    window.addEventListener("storage", function (event) {
      if (event.key === "accountToken" && event.newValue === null) {
        app.ports.removedItemPort.send({
          value: "",
          itemType: "AccountToken",
          action: "remove",
        });
      }
    });
  },
};

module.exports = LocalStoragePort;
