const IntercomPort = {
  add: function (app, key) {
    window.setupIntercom(key);

    app.ports.start.subscribe(function () {
      if (!window.Intercom) {
        return;
      }
      window.Intercom("boot");
    });
  },
};

module.exports = IntercomPort;
