const CustomJavascriptPort = {
  add: function (app) {
    app.ports.fetchCustomJsFile.subscribe(function (customJavascriptFileUrl) {
      var script = document.createElement("script");
      script.src = customJavascriptFileUrl;
      document.head.appendChild(script);
    });
  },
};

module.exports = CustomJavascriptPort;
