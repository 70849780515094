module Main exposing (main)

import Browser
import Browser.Dom as Dom
import Dict
import Ports.GoogleAnalytics as GoogleAnalytics
import Ports.Navigation exposing (onUrlChange)
import Ports.PageTitle exposing (updateTitle)
import Ports.Sentry as Sentry
import Shared exposing (Flags)
import Spa.Document as Document exposing (Document)
import Spa.Generated.Pages as Pages
import Spa.Layout
import Task
import Url exposing (Url)
import Utils.Route
import Utils.Url


main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view = view >> Document.toBrowserElement
        }



-- INIT


type alias Model =
    { shared : Shared.Model
    , page : Pages.Model
    , layout : Spa.Layout.Model
    }


init : Flags -> ( Model, Cmd Msg )
init flags =
    let
        ( url, defaultUrlCmd ) =
            locationHrefToUrl flags.locationHref

        ( shared, sharedCmd ) =
            Shared.init flags url

        route =
            Utils.Route.fromUrl url

        ( page, pageCmd, _ ) =
            Pages.init route shared

        layout =
            Spa.Layout.init route
    in
    ( Model shared page layout
    , Cmd.batch
        [ Cmd.map Shared sharedCmd
        , Cmd.map Pages pageCmd
        , defaultUrlCmd
        ]
    )



-- UPDATE


type Msg
    = UrlChanged String
    | ScrolledTop
    | Shared Shared.Msg
    | Pages Pages.Msg


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        UrlChanged locationHref ->
            let
                ( url, defaultUrlCmd ) =
                    locationHrefToUrl locationHref

                original =
                    model.shared

                ( shared, sharedCmd ) =
                    Shared.urlChanged url original

                route =
                    Utils.Route.fromUrl url

                ( page, pageCmd, _ ) =
                    Pages.init route shared

                { title } =
                    Pages.view page

                layout =
                    Spa.Layout.init route
            in
            ( { model | page = page, layout = layout, shared = Pages.save page shared }
            , Cmd.batch
                [ Cmd.map Shared sharedCmd
                , Cmd.map Pages pageCmd
                , GoogleAnalytics.pageView (Utils.Url.toPath url)
                , defaultUrlCmd
                , updateTitle title
                , Task.perform (\_ -> ScrolledTop) (Dom.setViewport 0 0)
                ]
            )

        Shared sharedMsg ->
            let
                ( shared, sharedCmd ) =
                    Shared.update sharedMsg model.shared

                ( page, pageCmd ) =
                    Pages.load model.page shared
            in
            ( { model | page = page, shared = shared }
            , Cmd.batch
                [ Cmd.map Shared sharedCmd
                , Cmd.map Pages pageCmd
                ]
            )

        Pages pageMsg ->
            let
                ( page, pageCmd, outMsgs ) =
                    Pages.update pageMsg model.page

                ( shared, sharedCmd ) =
                    Pages.save page model.shared
                        |> Shared.updateFromOutMsgs outMsgs

                { title } =
                    Pages.view page
            in
            ( { model | page = page, shared = shared }
            , Cmd.batch
                [ Cmd.map Pages pageCmd
                , Cmd.map Shared sharedCmd
                , updateTitle title
                ]
            )

        ScrolledTop ->
            ( model, Cmd.none )


view : Model -> Document Msg
view model =
    Shared.view
        { page =
            Pages.view model.page
                |> Document.map Pages
        , toMsg = Shared
        , layout = model.layout
        }
        model.shared


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ onUrlChange UrlChanged
        , Shared.subscriptions model.shared
            |> Sub.map Shared
        , Pages.subscriptions model.page
            |> Sub.map Pages
        ]


locationHrefToUrl : String -> ( Url, Cmd msg )
locationHrefToUrl locationHref =
    case Url.fromString locationHref of
        Just url ->
            ( url, Cmd.none )

        Nothing ->
            ( defaultUrl, Sentry.captureErrorMessage ("Url.fromString Failed: " ++ locationHref) Dict.empty )


defaultUrl : Url
defaultUrl =
    Url Url.Http "localhost" Nothing "" Nothing Nothing
