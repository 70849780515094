"use strict";

import { FullStory, init } from "@fullstory/browser";

const FullStoryPort = {
  add: function (app, orgId) {
    if (orgId) {
      init({ orgId: orgId });

      app.ports.fullStoryPortInit.subscribe(function (practice) {
        FullStory("setIdentity", {
          anonymous: true,
          properties: {
            practice_id: practice.id,
            practice_name: practice.name,
            practice_email: practice.email,
          },
        });
      });

      app.ports.fullStoryPortIdentifySignup.subscribe(
        function (onlineEnrollmentId) {
          FullStory("setIdentity", {
            uid: onlineEnrollmentId,
            properties: {
              online_enrollment_id: onlineEnrollmentId,
            },
          });
        },
      );

      app.ports.fullStoryPortIdentifyPatientPortal.subscribe(function (event) {
        FullStory("setIdentity", {
          uid: event.userId,
          properties: {
            authentication_method: event.authenticationMethod,
          },
        });
      });

      app.ports.fullStoryPortStopCapturing.subscribe(function () {
        FullStory("shutdown");
      });

      app.ports.fullStoryPortLogEvent.subscribe(function (event) {
        const options = Object.assign(event.payload, { app: "member" });
        FullStory("trackEvent", { name: event.type, properties: options });
      });
    }
  },
};

module.exports = FullStoryPort;
