const setup = function () {
  function loadRecaptchaAPI() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://www.google.com/recaptcha/api.js?render=" +
      CONFIGURATION.googleRecaptchaSiteKey +
      "&onload=recaptchaOnLoadfunction";
    document.body.appendChild(script);
  }

  if (CONFIGURATION.googleRecaptchaSiteKey) {
    window.addEventListener("load", loadRecaptchaAPI, false);
  }
};

window.recaptchaLoaded = false;

function notifyRecaptchaLoaded(app) {
  setTimeout(function () {
    app.ports.gotRecaptchaInitializedPort.send("Ok");
  }, 0);
}

const GoogleRecaptchaPort = {
  add: function (app) {
    window.recaptchaOnLoadfunction = function () {
      window.recaptchaLoaded = true;
      notifyRecaptchaLoaded(app);
    };

    app.ports.initializeRecaptchaPort.subscribe(function () {
      if (window.recaptchaLoaded) {
        notifyRecaptchaLoaded(app);
      }
    });

    setup();

    app.ports.getRecaptchaTokenPort.subscribe(function (action) {
      if (typeof grecaptcha !== "undefined") {
        grecaptcha.ready(function () {
          grecaptcha
            .execute(CONFIGURATION.googleRecaptchaSiteKey, { action: action })
            .then(function (token) {
              app.ports.gotRecaptchaTokenPort.send({
                token: token,
                result: "ok",
              });
            })
            .catch(() =>
              app.ports.gotRecaptchaTokenPort.send({
                token: "",
                result: "remote-error",
              }),
            );
        });
      } else {
        app.ports.gotRecaptchaTokenPort.send({
          token: "",
          result: "recaptcha-not-defined",
        });
      }
    });
  },
};

module.exports = GoogleRecaptchaPort;
