import * as Sentry from "@sentry/browser";

const SentryPort = {
  add: function (app, env) {
    app.ports.sentryPortCaptureErrorMessage.subscribe(
      function (messageAndContext) {
        const message = messageAndContext[0];
        const context = messageAndContext[1];

        if (!["staging", "production"].includes(env)) {
          console.error(message, context);
        }

        Sentry.withScope(function (scope) {
          Object.keys(context || {}).forEach(function (contextKey) {
            scope.setContext(contextKey, context[contextKey]);
          });

          Sentry.captureMessage(message, "info");
        });
      },
    );
  },
};

module.exports = SentryPort;
